<template>
  <div class="iot-base public-scroll-transparent">
    <el-form
      class="gateway-config-form"
      ref="formRef"
      v-model="gatewayForm"
      label-position="left"
      :rules="rules"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="目标服务器" name="0">
          <el-radio
            :disabled="currentPlanId != 0"
            style="padding: 30px 0px"
            v-model="gatewayForm.serverType"
            v-for="(item, index) in constant.serverType"
            :label="item.key"
            :key="index"
            @change="changeUploadMode"
            >{{ item.value }}</el-radio
          >
        </el-tab-pane>
      </el-tabs>
      <el-form-item
        v-show="gatewayForm.serverType == 1"
        label="服务器"
        prop="serverId"
      >
        <el-select
          v-model="gatewayForm.serverId"
          placeholder="请选择服务器"
          clearable
          size="small"
        >
          <el-option
            v-for="item in serverList"
            :key="item.serverId"
            :value="item.serverId"
            :label="item.serverName"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否携带ID:" label-width="150px" v-if="gatewayForm.serverType==0">
      <el-radio-group v-model="gatewayForm.isCarryId">
         <el-radio :label="0">是</el-radio>
          <el-radio :label="1">否</el-radio>
    </el-radio-group>
          </el-form-item>
      <el-form-item
        label="上传模式"
        name="0"
      >
        <el-radio v-model="gatewayForm.uploadMode" v-show="gatewayForm.serverType != 1" :label="0">默认</el-radio>
        <el-radio v-model="gatewayForm.uploadMode" :label="1">仅定时</el-radio>
        <el-radio v-model="gatewayForm.uploadMode" :label="2">仅变化</el-radio>
        <el-radio v-model="gatewayForm.uploadMode" :label="3">定时加变化</el-radio>
      </el-form-item>
      <el-form-item label="上传周期:" prop="upload" label-width="75px">
        <el-input
          v-model="gatewayForm.uploadPeriod"
          placeholder="请输入2到86400之间的数"
          size="mini"
          style="width: 250px"
        ></el-input>
        <span style="display:inline-block;margin-left:10px">秒</span>
        <span style="color:red;margin:0 0 0 10px;">避免流量消耗过大，不建议小于60！</span>
      </el-form-item>
      <el-form-item label="仅上传有效值" name="0">
        <el-radio v-model="isUploadValid" :label="0">否(默认)</el-radio>
        <el-radio v-model="isUploadValid" :label="1">是</el-radio>
      </el-form-item>
      <el-form-item label="系统参数" name="0">
        <el-radio v-model="gatewayForm.isUploadAttr" :label="0">不上传</el-radio>
        <el-radio v-model="gatewayForm.isUploadAttr" :label="1">上传</el-radio>
      </el-form-item>
      <!-- <el-form-item
        v-show="gatewayForm.serverType == 1"
        label="数据变化上传"
        name="0"
      >
        <el-radio v-model="isChangeUpload" :label="0">否(默认)</el-radio>
        <el-radio v-model="isChangeUpload" :label="1">是</el-radio>
      </el-form-item> -->
   
      <!-- 网关多选列表 -->
      <el-tabs v-if="gatewayForm.serverType == 0" v-model="activeName">
        <el-tab-pane label="网关列表" name="0">
          <selectComponent
            @selectChange="selectChange"
            :editData="edit"
            :checkData="gatewayList"
            checkType="radio"
          ></selectComponent>
        </el-tab-pane>
      </el-tabs>
      <!-- 网关单选列表 -->
      <el-tabs v-if="gatewayForm.serverType == 1" v-model="activeName">
        <el-tab-pane label="网关列表" name="0">
          <selectComponent
            @selectChange="selectChange"
            :editData="edit"
            :checkData="gatewayList"
            checkType="radio"
          ></selectComponent>
          <!-- <el-radio
            class="iot-gateway-config-item"
            v-model="gatewayForm.gatewayId"
            v-for="item in gatewayList"
            :label="item.gatewayId"
            :key="item.gatewayId"
          >
            <el-image
              :src="constant.OSS_URL + constant.GATEWAY_IMG + item.gatewayImg"
            ></el-image>
            <div>{{ item.gatewayName }}</div>
          </el-radio> -->
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>
<script>
import { OSS_URL, GATEWAY_IMG, serverType } from "../util/constant";
import selectComponent from "./selectComponent.vue";
let that;
export default {
  name: "GatewayConfig",
  components: { selectComponent },
  props: {
    currentPlanId: {
      type: Number,
      required: true,
    },
    editData: {
      type: Object,
      required: true,
    },
  },
  data() {
    var numberValue = (rule, value, callback) => {
      if (!this.gatewayForm.uploadPeriod) {
        return callback(new Error("上传周期不能为空"));
      } else if (this.gatewayForm.uploadPeriod < 2) {
        callback(new Error("上传周期输入值不能小于2"));
      } else if (this.gatewayForm.uploadPeriod > 86400) {
        callback(new Error("上传周期输入值不能大于于86400"));
      }
    };

    return {
      edit: this.editData,
      constant: { OSS_URL, GATEWAY_IMG, serverType },
      isCreate: true,
      activeName: "0",

      formTile: "",
      gatewayForm: {
        isCarryId:1,
        planId: "",
        serverType: 0,
        gatewayId: "",
        gatewayIds: [],
        serverId: "",
        uploadPeriod: "",
        isUploadValid: "0",
        uploadMode: 0,
        isUploadAttr:1
      },
      isUploadValid: 0,
      uploadMode: 0,
      checkAll: false,
      isIndeterminate: true,
      gatewayList: [],
      serverList: [],
      dialogFormVisible: false,
      rules: {
        upload: [{ validator: numberValue, trigger: "blur" }],
      },
    };
  },
  methods: {
    changeUploadMode(){
      if (that.gatewayForm.serverType==1) {
        that.gatewayForm.uploadMode=1
        that.gatewayForm.serverId = that.serverList[0].serverId
        that.gatewayForm.isUploadAttr=0
        that.gatewayForm.isCarryId=0
      }else  {
        that.gatewayForm.uploadMode=0
        that.gatewayForm.serverId = ""
        that.gatewayForm.isUploadAttr = 1
        that.gatewayForm.isCarryId=1
      }
    },


    //输入数值
  

    selectChange: function (d) {
      switch (d.checkType) {
        case "checkbox":
          that.$set(that.gatewayForm, "gatewayIds", d.data);
          break;
        case "radio":
          that.$set(that.gatewayForm, "gatewayId", d.data);
          break;
        default:
          break;
      }
    },
    getGatewayList() {
      that.$api.gateway.getGatewayList().then((response) => {
        if (response.code == 200) {
          that.gatewayList = response.data;
        }
      });
    },
    getGatewayDetail() {
      that.$api.gateway.getGatewayList().then((response) => {
        if (200 == response.code) {
          if (response.data.gatewayIds) {
            if (response.data.serverType == 0) {
              that.$set(
                that.gatewayForm,
                "gatewayIds",
                response.data.gatewayIds
              );
              that.$set(
                that.gatewayForm,
                "serverType",
                response.data.serverType
              );
            } else {
              that.$set(that.gatewayForm, "gatewayId", response.data.gatewayId);
              that.$set(
                that.gatewayForm,
                "serverType",
                response.data.serverType
              );
              that.$set(that.gatewayForm, "serverId", response.data.serverId);
            }
          }
        }
      });
    },

    getServerList() {
      that.$api.server.getServerList().then((response) => {
        if (200 == response.code) {
          that.serverList = response.data;
        }
      });
    },
    handleCheckAllChange(val) {
      let ids = new Array();
      if (val) {
        that.gatewayList.forEach((element) => {
          ids.push(element.gatewayId);
        });
        that.$set(that.gatewayForm, "gatewayIds", ids);
      } else {
        that.$set(that.gatewayForm, "gatewayIds", ids);
      }
      that.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      that.checkAll = checkedCount === that.gatewayList.length;
      that.isIndeterminate =
        checkedCount > 0 && checkedCount < that.gatewayList.length;
      that.$set(that.gatewayForm, "gatewayIds", value);
    },
    submitForm() {
      that.gatewayForm.isUploadValid = that.isUploadValid;
     
      that.$emit("subData", that.gatewayForm);
      // let _api = that.isCreate
      //   ? that.$api.plan.addPlanGatewayRel(that.gatewayForm)
      //   : that.$api.plan.editPlanGatewayRel(that.gatewayForm);
      // _api.then((response) => {
      //   if (response.code == 200) {
      //     that.$message({
      //       type: "success",
      //       message: response.msg,
      //     });
      //     that.isCreate = true;
      //   }
      // });
    },
  },
  beforeMount() {},
  created() {
    that = this;
    // console.log(that.editData);
    that.getGatewayList();
    that.getServerList();
    if (!that.isNew) {
      that.getGatewayDetail();
    }
      that.gatewayForm.uploadPeriod = "300";
      that.gatewayForm.isUploadValid = 0;
      that.gatewayForm.uploadMode = 0;
      that.gatewayForm.serverType = 0;
    if (Object.keys(that.editData).length > 0) {
      that.gatewayForm = that.editData;
    }
    that.isUploadValid = that.gatewayForm.isUploadValid;
      that.uploadMode = that.gatewayForm.uploadMode;
  },
};
</script>

<style scoped>
.el-select,
.el-input,
.el-input-number {
  width: 250px;
}
/* .iot-base {
  width: 90%;
  margin-left: 2%;
} */
.iot-gateway-config-items {
  width: 100%;
  max-height: 350px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  line-height: 20px;
}
.iot-gateway-config-item {
  width: 18%;
  max-height: 350px;
  margin: 5px;
  padding: 3px;
  cursor: pointer;
  text-align: left;
}
.iot-gateway-config-item div {
  display: inline-block;
}

.iot-gateway-config-item .el-image {
  height: 20px;
}
.gateway-config-form {
  padding: 0 30px 30px 30px;
}
.gateway-config-but {
  float: right;
  margin: 20px;
}
</style>
